<template>
  <div style="padding-top: 50px">
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <div>
          <div style="width: 100%;display: flex;justify-content: end">
            <span style="color: white;font-size: 20px;font-weight: bold;">账号信息</span>
          </div>
          <div style="height: 100px;width: 100%;background-color: rgba(17,17,17,0.7);"></div>
          <img width="100" height="100" :src="userInfo['avatar']"  alt="" style="position: absolute;top: 10px;left: 20px"/>
          <div class="nicknameDiv" v-if="loggedIn" style="position: absolute;top: 35px;left: 140px;color: white;font-size: 30px">{{ userInfo['nickname'] }}</div>
          <div v-else style="position: absolute;top: 35px;left: 140px;">
            <el-link @click="navigateToAuth('login')" type="primary" style="font-size: 30px" :underline="false">BattlefieldTools</el-link>
          </div>
          <div v-if="loggedIn" style="position: absolute;top: 75px;left: 140px;color: white;font-size: 15px"><el-button @click="logout" style="font-size: 15px" type="text">退出</el-button></div>
          <div v-else style="position: absolute;top: 75px;left: 140px;color: white;font-size: 15px">更多功能请<el-button @click="navigateToAuth('register')" style="font-size: 15px" type="text">注册</el-button>/<el-button @click="navigateToAuth('login')" style="font-size: 15px;margin-left: 0" type="text">登录</el-button></div>
        </div>

        <div>
          <div v-if="loggedIn" style="margin-top: 20px;width: 100%;display: flex;justify-content: end">
            <span style="color: white;font-size: 20px;font-weight: bold;">群组信息</span>
          </div>
          <div v-if="loggedIn" style="padding: 10px;margin-top: 5px;min-height: 50px;width: 100%;background-color: rgba(17,17,17,0.7);">
            <a-collapse :bordered="false" @change="groupCollapseChange($event)" accordion>
              <a-collapse-panel v-for="(group, index) in groups" :key="index">
                <template #header>
                  {{ group['name'] }}
                </template>
                <a-collapse :bordered="false">
                  <a-collapse-panel key="1" header="服务器">
                    <el-table @row-click="handleGroupServerTableRowClick" class="server_table" v-loading="serverListLoading" element-loading-background="rgba(0, 0, 0, 1)" :data="group['servers']" style="width: 100%;" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', padding: '2px'}">
                      <el-table-column label="地图" width="120" align="center">
                        <template v-slot="scope">
                          <img style="width: 81px; height: 50px" :src="scope['row']['map']['url']" alt="">
                        </template>
                      </el-table-column>
                      <el-table-column label="名称" min-width="100" show-overflow-tooltip>
                        <template v-slot="scope">
                          <div>
                            <div style="font-size: 17px;font-weight: 500">
                              {{ scope['row']['name'] }}
                            </div>
                            <div>
                              {{ Bf1Country[scope['row']['country']] }} - {{  scope['row']['map']['name'] }} - {{ scope['row']['mode']['name'] }}
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="玩家" width="180" align="center">
                        <template v-slot="scope">
                          <div style="font-size: 18px;font-weight: 500">
                            {{ scope['row']['slots']['soldier']['current'] + ' / ' + scope['row']['slots']['soldier']['max'] + ' [' + scope['row']['slots']['queue']['current'] + ']' + ' (' + scope['row']['slots']['spectator']['current'] + ')'}}
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <a-button style="margin-top: 5px" size="default" class="hoverButton" ghost block @click="showBindServer(group)">添加服务器</a-button>
                    <a-button style="margin-top: 5px" size="default" class="hoverButton" ghost block @click="batchQueryGroupServer(group)">刷新数据</a-button>
                  </a-collapse-panel>
                  <a-collapse-panel key="2" header="成员">
                    <el-table  v-loading="memberListLoading"  element-loading-background="rgba(0, 0, 0, 1)" :data="group['members']" style="width: 100%;" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', padding: '2px'}">
                      <el-table-column label="头像" width="120" align="center">
                        <template v-slot="scope">
                          <img width="50px" height="50px" slot="avatar" :src="scope['row']['avatar']" alt=""/>
                        </template>
                      </el-table-column>
                      <el-table-column label="昵称" min-width="100" show-overflow-tooltip>
                        <template v-slot="scope">
                          <div>
                            <div style="font-size: 17px;font-weight: 500">
                              {{ scope['row']['nickname'] }}
                            </div>
                            <div>
                              <a-tag slot="description" :color="GroupMemberPermissionLevel[scope['row']['permissionLevel']]['color']">{{ GroupMemberPermissionLevel[scope['row']['permissionLevel']]['label'] }}</a-tag>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" width="120" align="center">
                        <template v-slot="scope">
                          <div>
                            <a-icon @click="removeMember(scope['row'], group)" v-if="group['ownerUserId'] === getCookie('id') && scope['row']['permissionLevel'] !== 30" type="minus-square" theme="filled" style="font-size: 20px;cursor: pointer;margin-left: 20px"/>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <a-button style="margin-top: 5px" size="default" class="hoverButton" ghost block @click="showAddMember(group)">添加成员</a-button>
                    <a-button style="margin-top: 5px" size="default" class="hoverButton" ghost block @click="batchQueryGroupMember(group)">刷新数据</a-button>
                  </a-collapse-panel>
                </a-collapse>
                <a-button v-if="group['ownerUserId'] === getCookie('id')" style="margin-top: 5px" size="default" class="hoverButton" ghost block @click="showDeleteGroup(group)">删除{{group['name']}}</a-button>
              </a-collapse-panel>
            </a-collapse>
            <a-button style="margin-top: 5px;" size="default" class="hoverButton" ghost block @click="showCreateGroup">创建群组</a-button>
          </div>
        </div>
      </a-col>
    </a-row>

    <el-dialog title="操作" :visible.sync="addMemberVisible" width="400px" center :show-close="false">
      <a-row :gutter="[0,5]">
        <a-col>
          <span style="color: #dedede;font-size: 15px">
            1. 用户名为注册时的昵称
          </span>
        </a-col>
        <a-col>
          <el-input class="transparentElement" style="margin-top: 20px;" v-model="addMemberFrom['username']" placeholder="请输入用户名"/>
        </a-col>
        <a-col span="24">
          <a-button @click="addMember" :disabled="processing" style="height: 40px;" size="default" class="hoverButton" ghost block>添加</a-button>
        </a-col>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <span @click="addMemberVisible = false" style="color: white;cursor: pointer;font-size: 18px;border-bottom: 1px solid white;padding-bottom: 5px;padding-left: 10px;padding-right: 10px">关 闭</span>
      </span>
    </el-dialog>

    <el-dialog title="操作" :visible.sync="bindServerVisible" width="400px" center :show-close="false">
      <a-row :gutter="[0,5]">
        <a-col>
          <span style="color: #dedede;font-size: 15px">
            1. 服务器GameId可通过机器人或网站获取
            <br>
            2. 需要使用服主的SessionId确认身份
          </span>
        </a-col>
        <a-col>
          <el-input class="transparentElement" style="margin-top: 20px;" v-model="bindServerFrom['gameId']" placeholder="请输入GameId"/>
        </a-col>
        <a-col>
          <el-input class="transparentElement" v-model="bindServerFrom['sessionId']" placeholder="请输入SessionId"/>
        </a-col>
        <a-col>
          <a-button @click="bindServer" :disabled="processing" style="height: 40px;" size="default" class="hoverButton" ghost block>绑定</a-button>
        </a-col>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <span @click="bindServerVisible = false" style="color: white;cursor: pointer;font-size: 18px;border-bottom: 1px solid white;padding-bottom: 5px;padding-left: 10px;padding-right: 10px">关 闭</span>
      </span>
    </el-dialog>

    <el-dialog title="操作" :visible.sync="createGroupVisible" width="400px" center :show-close="false">
      <a-row :gutter="[0,5]">
        <a-col>
          <el-input class="transparentElement" style="margin-top: 20px;" v-model="createGroupFrom['name']" placeholder="请输入群组名称"/>
        </a-col>
        <a-col>
          <el-input class="transparentElement" v-model="createGroupFrom['desc']" placeholder="请输入群组描述"/>
        </a-col>
        <a-col>
          <a-button @click="createGroup" :disabled="processing" style="height: 40px;" size="default" class="hoverButton" ghost block>创建</a-button>
        </a-col>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <span @click="createGroupVisible = false" style="color: white;cursor: pointer;font-size: 18px;border-bottom: 1px solid white;padding-bottom: 5px;padding-left: 10px;padding-right: 10px">关 闭</span>
      </span>
    </el-dialog>

    <el-dialog title="操作" :visible.sync="deleteGroupVisible" width="400px" center :show-close="false">
      <a-row :gutter="[0,5]">
        <a-col>
          <span style="color: #dedede;font-size: 15px">
            1. 删除群组为不可逆行为
            <br>
            2. 同时会删除成员与服务器绑定信息
          </span>
        </a-col>
        <a-col>
          <a-button @click="deleteGroup" :disabled="processing" style="height: 40px;" size="default" class="hoverButton" ghost block>删除</a-button>
        </a-col>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <span @click="deleteGroupVisible = false" style="color: white;cursor: pointer;font-size: 18px;border-bottom: 1px solid white;padding-bottom: 5px;padding-left: 10px;padding-right: 10px">关 闭</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {clearUserCookie, getToken, getCookie} from "../../utils/local-storage";
import {GroupMemberPermissionLevel} from "../../enum/group-member-permission-level";
import {logoutAuthApi} from "../../request/api/auth";
import {batchQueryGroupApi, createGroupApi, deleteGroupApi} from "../../request/api/group";
import {batchQueryGroupServerApi, createGroupServerApi} from "../../request/api/group-server";
import {batchQueryGroupMemberApi, createGroupMemberApi, deleteGroupMemberApi} from "../../request/api/group-member";
import {queryServerApi} from "../../request/api/server";
import {Bf1Country} from "../../enum/bf1-enum";
import {DefaultValue} from "../../enum/default-value";

export default {
  name: "center",
  data() {
    return {
      Bf1Country: Bf1Country,
      GroupMemberPermissionLevel: GroupMemberPermissionLevel,
      DefaultValue: DefaultValue,
      loggedIn: false,
      memberListLoading: false,
      serverListLoading: false,
      processing: false,
      activeGroup: "",
      addMemberVisible: false,
      bindServerVisible: false,
      createGroupVisible: false,
      deleteGroupVisible: false,
      currentGroup: null,
      currentServer: null,
      addMemberFrom: {
        username: "",
        groupId: "",
      },
      bindServerFrom: {
        sessionId: "",
        groupId: "",
        gameId: "",
      },
      createGroupFrom: {
        name: "",
        desc: "",
      },
      userInfo: {
        avatar: DefaultValue.defaultAvatar,
        email: "",
        lastLoginIp: "",
        lastLoginTime: "",
        nickname: "",
        createTime: "",
        uuid: "",
        status: 0,
      },
      groups:[]
    }
  },
  created() {
    this.$emit('changeKey', '5')
    this.loggedIn = null !== getToken();
    if (this.loggedIn) {
      this.refreshGroupList();
      this.getUserInfoFromCookie(["id", "username", "avatar", "nickname", "status"])
    }
  },
  methods: {
    getCookie,
    logout() {
      logoutAuthApi().then((response)=>{
        this.loggedIn = false
        clearUserCookie()
        this.$notify({
          title: '成功',
          message: '已退出登录状态',
          type: 'success'
        });
      })
    },
    navigateToServerDetail(gameId) {
      this.$router.push({
        path: "/server/info",
        query: {
          gameId: gameId,
          from: 5
        }
      });
    },
    navigateToAuth(type) {
      if (type) {
        this.$router.push({
          path: "/auth",
          query: {
            type: type,
          }
        });
      } else {
        this.$router.push("/auth");
      }
    },
    getUserInfoFromCookie(cookieNames) {
      cookieNames.forEach((item)=>{
        let cookieValue = getCookie(item);
        if (cookieValue) {
          this.userInfo[item] = cookieValue;
        }
      })
    },
    refreshGroupList() {
      batchQueryGroupApi().then((response)=>{
        this.groups = response['data']['data']
      })
    },
    groupCollapseChange(event) {
      if (event === undefined) {
        return
      }
      let group = this.groups[event];
      this.batchQueryGroupServer(group)
      this.batchQueryGroupMember(group)
    },
    batchQueryGroupMember(group) {
      this.memberListLoading = true;
      batchQueryGroupMemberApi(group['id']).then((res) => {
        for (let i = 0; i < res['data']['data'].length; i++) {
          let groupMember = res['data']['data'][i];
          if (groupMember['avatar'] === null) {
            groupMember['avatar'] = DefaultValue.defaultAvatar
          }
        }
        group['members'] = res['data']['data']
        this.$forceUpdate()
      }).finally(() => {
        this.memberListLoading = false;
      })
    },
    batchQueryGroupServer(group) {
      this.serverListLoading = true;
      batchQueryGroupServerApi(group['id']).then(async (groupServerRes) => {
        let serverResArr = []
        let servers = []
        for (let i = 0; i < groupServerRes['data']['data'].length; i++) {
          let groupServer = groupServerRes['data']['data'][i];
          serverResArr.push(queryServerApi(groupServer['gameId']))
        }
        for (let i = 0; i < serverResArr.length; i++) {
          let serverRes = await serverResArr[i];
          servers.push(serverRes['data']['data'])
        }
        servers.sort((s1, s2)=>{
          let s1t = s1['slots']['soldier']['current'] + s1['slots']['queue']['current'];
          let s2t = s2['slots']['soldier']['current'] + s2['slots']['queue']['current'];
          return s2t > s1t ? 1 : s1t > s2t ? -1 : 0
        })
        group['servers'] = servers
        this.$forceUpdate()
      }).finally(() => {
        this.serverListLoading = false;
      })
    },
    showBindServer(group) {
      this.bindServerFrom = {
        sessionId: "",
        gameId: "",
        groupId: ""
      }
      this.currentGroup = group;
      this.bindServerFrom['groupId'] = group['id'];
      this.bindServerVisible = true;
    },
    showCreateGroup() {
      this.createGroupFrom = {
        name: "",
        desc: "",
      }
      this.createGroupVisible = true;
    },
    showDeleteGroup(group) {
      this.currentGroup = group;
      this.deleteGroupVisible = true;
    },
    handleGroupServerTableRowClick(row) {
      this.navigateToServerDetail(row['gameId'])
    },
    showAddMember(group) {
      this.addMemberFrom = {
        username: "",
        groupId: "",
      }
      this.currentGroup = group;
      this.addMemberFrom['groupId'] = group['id'];
      this.addMemberVisible = true;
    },
    createGroup() {
      if (!this.createGroupFrom['name']) {
        this.$notify({
          title: '警告',
          message: "请输入群组名称",
          type: 'warning'
        });
        return;
      }
      if (!this.createGroupFrom['desc']) {
        this.$notify({
          title: '警告',
          message: "请输入群组描述",
          type: 'warning'
        });
        return;
      }
      this.processing = true;
      createGroupApi(this.createGroupFrom.name, this.createGroupFrom.desc).then(()=>{
        this.$notify({
          title: '成功',
          message: '创建成功',
          type: 'success'
        });
        this.createGroupVisible = false
        this.refreshGroupList();
      }).finally(()=>{
        this.processing = false;
      })
    },
    deleteGroup() {
      this.processing = true;
      deleteGroupApi(this.currentGroup['id']).then(()=>{
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        this.deleteGroupVisible = false;
        this.refreshGroupList();
      }).finally(()=>{
        this.processing = false;
      })
    },
    bindServer() {
      if (!this.bindServerFrom['sessionId']) {
        this.$notify({
          title: '警告',
          message: "请输入SessionId",
          type: 'warning'
        });
        return;
      }
      if (!this.bindServerFrom['gameId']) {
        this.$notify({
          title: '警告',
          message: "请输入GameId",
          type: 'warning'
        });
        return;
      }
      this.processing = true;
      createGroupServerApi(this.bindServerFrom.groupId, this.bindServerFrom.gameId, this.bindServerFrom.sessionId).then(()=>{
        this.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success'
        });
        this.bindServerVisible = false
        this.batchQueryGroupServer(this.currentGroup)
      }).finally(()=>{
        this.processing = false;
      })
    },
    addMember() {
      if (!this.addMemberFrom['username']) {
        this.$notify({
          title: '警告',
          message: "请输入用户名",
          type: 'warning'
        });
        return;
      }
      this.processing = true;
      createGroupMemberApi(this.addMemberFrom.groupId, this.addMemberFrom.username).then(()=>{
        this.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success'
        });
        this.bindServerVisible = false
        this.batchQueryGroupMember(this.currentGroup)
      }).finally(()=>{
        this.processing = false;
      })
    },
    removeMember(groupMember, group) {
      this.processing = true;
      deleteGroupMemberApi(groupMember['id']).then(()=>{
        this.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success'
        });
        this.batchQueryGroupMember(group)
      }).finally(()=>{
        this.processing = false;
      })
    }
  }
}
</script>

<style scoped>
.ant-collapse {
  background-color: transparent;
}
::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: white !important;
}
.ant-list-item-meta-title {
  color: white;
}
.ant-list-item-meta-description {
  color: white;
}
.ant-list {
  color: white;
}

::v-deep .el-dialog__header {
  background-color: #343434;
  padding: 10px
}
::v-deep .el-dialog__title {
  color: white;
}
::v-deep .el-dialog__body {
  background-color: #111111;
}
::v-deep .el-dialog__footer {
  background-color: #111111;
}


/* 按钮鼠标移入聚焦 */
.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}

/* 透明输入框 */
::v-deep .transparentElement .el-input__inner {
  /* 使input框的背景变透明 */
  background-color: transparent;
  /* 使边框也变透明 */
  border-color: white;
  /* 改变获取焦点后的竖线颜色 */
  caret-color: white;
  color:#FFFFFF;
}
/* 透明输入框聚焦 */
::v-deep .transparentElement .el-input__inner:focus {
  border-color: #e6a23c;
}
::v-deep .el-select-dropdown {
  background-color: #343434;
}

/* 下拉框 */
::v-deep .el-select-dropdown__item {
  color: white;
  margin-left: 5px;
  margin-right: 5px;
}
::v-deep .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  color: black;
  margin-left: 5px;
  margin-right: 5px;
}

/* tag */
.ant-tag {
  background-color: rgba(255, 255, 255, 0);
  font-weight: bold;
}
.ant-tag-blue {
  color: #1890ff;
}
.ant-tag-green {
  color: #52c41a;
}



::v-deep .el-table, ::v-deep .el-table__expanded-cell{
  background-color: transparent;
}
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
::v-deep .el-table tbody tr:hover>td {
  background: rgb(255, 255, 255) !important;
  color: #000000 !important;
}
::v-deep .el-table tr,
::v-deep .el-table td {
  border-bottom: none !important;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.server_table ::v-deep.el-table .el-table__row:hover {
  background-color: #f5f5f5; /* 设置悬停时的背景颜色 */
  cursor: pointer; /* 将鼠标样式设置为手型 */
}


::v-deep .el-loading-spinner .el-loading-text {
  color: white;
}
::v-deep .el-loading-spinner .path {
  stroke: white;
}

/** <576px **/
@media screen and (max-width: 576px) {
}
/** ≥576px **/
@media screen and (min-width: 576px) {
}
/** ≥768px **/
@media screen and (min-width: 768px) {
}
/** ≥992px **/
@media screen and (min-width: 992px) {
}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {
}
</style>